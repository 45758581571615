import React, { useContext } from 'react';
import bardahlImg from '../../assets/images/bardahl.png';
import bernerImg from '../../assets/images/berner.jpg';
import bridgestoneImg from '../../assets/images/bridgestone.png';
import carlockImg from '../../assets/images/carlock.jpg';
import continentalImg from '../../assets/images/continental.png';
import coyoteImg from '../../assets/images/coyote.png';
import dkSchweizerImg from '../../assets/images/dk-schweizer.png';
import facomImg from '../../assets/images/facom.png';
import garminImg from '../../assets/images/garmin.png';
import goodyearImg from '../../assets/images/goodyear.png';
import hebicImg from '../../assets/images/hebic.png';
import hereImg from '../../assets/images/here.png';
import innotecImg from '../../assets/images/innotec.png';
import isofilmImg from '../../assets/images/isofilm.png';
import kentoImg from '../../assets/images/kento.png';
import lhommeImg from '../../assets/images/lhomme.png';
import michelinImg from '../../assets/images/michelin.png';
import motexionImg from '../../assets/images/motexion.png';
import msEuropeImg from '../../assets/images/ms-europe.png';
import mtsImg from '../../assets/images/mts.png';
import pioneerImg from '../../assets/images/pioneer.png';
import pirelliImg from '../../assets/images/pirelli.png';
import riwaxImg from '../../assets/images/riwax.png';
import samartImg from '../../assets/images/samart.png';
import stcEuropeImg from '../../assets/images/stc-europe.png';
import thuleImg from '../../assets/images/thule.jpg';
import tunapImg from '../../assets/images/tunap.png';
import wurthImg from '../../assets/images/wurth.png';
import { TranslateContext } from '../../Store/translate.reducer';
import './Parternship.scss';

export default function Parternship() {
    const t = useContext(TranslateContext);

    return (
        <section className="parternship-section" id="parternship">
            <h2>{t.text('OUR_SPONSOR')}</h2>
            <div className="parternship">
                <a href="https://www.pneus-continental.be/" target="_blank">
                    <img src={continentalImg} alt="continental" />
                </a>
                <a href="#">
                    <img src={bernerImg} alt="berner" />
                </a>
                <a href="https://www.facom.be/" target="_blank">
                    <img src={facomImg} alt="facom" />
                </a>
                <a href="https://www.coyotesystems.be/" target="_blank">
                    <img src={coyoteImg} alt="coyote" />
                </a>
                <a href="https://www.dk-schweizer.be/" target="_blank">
                    <img src={dkSchweizerImg} alt="dkSchweizer" />
                </a>
                <a href="https://www.bridgestone.be/" target="_blank">
                    <img src={bridgestoneImg} alt="bridgestone" />
                </a>
                <a href="https://www.carlocksystems.com/fr/16541-2/" target="_blank">
                    <img src={carlockImg} alt="carlock" />
                </a>
                <a href="https://www.here.com/" target="_blank">
                    <img src={hereImg} alt="here" />
                </a>
                <a href="https://www.bardahl.fr/fr-fr/accueil" target="_blank">
                    <img src={bardahlImg} alt="bardahl" />
                </a>
                <a href="https://www.goodyear.eu/fr_fr/consumer.html" target="_blank">
                    <img src={goodyearImg} alt="goodyear" />
                </a>
                <a href="https://www.hebic.be/" target="_blank">
                    <img src={hebicImg} alt="hebic" />
                </a>
                <a href="https://bel.garmin.com/" target="_blank">
                    <img src={garminImg} alt="garmin" />
                </a>
                <a href="http://www.innotec-industrie.be/be/" target="_blank">
                    <img src={innotecImg} alt="innotec" />
                </a>
                <a href="https://isofilm.be/home/fr/isofilm" target="_blank">
                    <img src={isofilmImg} alt="isofilm" />
                </a>
                <a href="https://www.kenteurope.com/fr/" target="_blank">
                    <img src={kentoImg} alt="kento" />
                </a>
                <a href="https://www.lhommetools.be/" target="_blank">
                    <img src={lhommeImg} alt="lhomme" />
                </a>
                <a href="https://www.michelin.be/fr/" target="_blank">
                    <img src={michelinImg} alt="michelin" />
                </a>
                <a href="https://motexion.nl/" target="_blank">
                    <img src={motexionImg} alt="motexion" />
                </a>
                <a href="https://mseurope.be/" target="_blank">
                    <img src={msEuropeImg} alt="msEurope" />
                </a>
                <a href="http://www.mts-imperialen.com/" target="_blank">
                    <img src={mtsImg} alt="mts" />
                </a>
                <a href="https://www.pioneer.eu/" target="_blank">
                    <img src={pioneerImg} alt="pioneer" />
                </a>
                <a href="https://www.pirelli.com/global/en-ww/homepage" target="_blank">
                    <img src={pirelliImg} alt="pirelli" />
                </a>
                <a href="https://www.riankar.be/" target="_blank">
                    <img src={riwaxImg} alt="riwax" />
                </a>
                <a href="https://www.samart.com/en" target="_blank">
                    <img src={samartImg} alt="samart" />
                </a>
                <a href="https://www.stceurope.com/" target="_blank">
                    <img src={stcEuropeImg} alt="stcEurope" />
                </a>
                <a href="https://www.thule.com/fr-fr" target="_blank">
                    <img src={thuleImg} alt="thule" />
                </a>
                <a href="https://www.tunap.be/" target="_blank">
                    <img src={tunapImg} alt="tunap" />
                </a>
                <a href="https://eshop.wurth.be/nl/NL/EUR" target="_blank">
                    <img src={wurthImg} alt="wurth" />
                </a>
            </div>
        </section>
    );
}
