import React, { useContext } from 'react';
import { TranslateContext } from '../../Store/translate.reducer';
import fiatProImg from '../../assets/images/FIAT_professional.png';
import jeepImg from '../../assets/images/Jeep.png';
import lukLogo from '../../assets/images/LUK-Logo.jpg';
import lanciaImg from '../../assets/images/Lancia.png';
import snrImg from '../../assets/images/SNR.png';
import abarthImg from '../../assets/images/abarth.png';
import alphaImg from '../../assets/images/alpha.png';
import blueprintImg from '../../assets/images/blueprint.png';
import vanImg from '../../assets/images/camionette.png';
import chryslerImg from '../../assets/images/chrysler.png';
import citroenImg from '../../assets/images/citroen-logo.png';
import dsImg from '../../assets/images/distrigo-psa-img-ds.png';
import febiImg from '../../assets/images/distrigo-psa-img-febi-1.png';
import juridImg from '../../assets/images/distrigo-psa-img-jurid.png';
import ngkImg from '../../assets/images/distrigo-psa-img-ngk.jpg';
import purfluxImg from '../../assets/images/distrigo-psa-img-purflux.png';
import seimImg from '../../assets/images/distrigo-psa-img-seim.png';
import skfImg from '../../assets/images/distrigo-psa-img-skf.png';
import dodgeImg from '../../assets/images/dodge.png';
import euroreparImg from '../../assets/images/eurorepar.jpg';
import ferodoImg from '../../assets/images/ferodo.png';
import fiatImg from '../../assets/images/fiat.png';
import peugeotImg from '../../assets/images/logo-peugeot.png';
import monroeImg from '../../assets/images/monroe.png';
import nissensImg from '../../assets/images/nissens_logo_vece.jpg';
import opelImg from '../../assets/images/opel-Logo.png';
import payenImg from '../../assets/images/payen.png';
import valeoImg from '../../assets/images/valeo.jpg';
import './MainSection.scss';

export default function MainSection() {
    const t = useContext(TranslateContext);
    return (
        <>
            <section
                id="banner"
                className="img"
                // style={
                //     {
                //         // backgroundImage: `url(${magasin2Img})`,
                //     }
                // }
            >
                <div className="content">
                    <h1>{t.text('YOUR_PARTNER')}</h1>
                    <div className="img-wrapper">
                        <div>
                            <img src={peugeotImg} alt="peugeot" />
                        </div>
                        <div>
                            <img src={citroenImg} alt="citroen" />
                        </div>
                        <div>
                            <img src={dsImg} alt="ds" />
                        </div>
                        <div>
                            <img src={opelImg} alt="opel" />
                        </div>

                        <div>
                            <img src={abarthImg} alt="Abarth" />
                        </div>
                        <div>
                            <img src={fiatImg} alt="Fiat" />
                        </div>
                        <div>
                            <img src={fiatProImg} alt="Fiat Professionnal" />
                        </div>
                        <div>
                            <img src={alphaImg} alt="Alpha Romeo" />
                        </div>
                        <div>
                            <img src={lanciaImg} alt="Lancia" />
                        </div>
                        <div>
                            <img src={jeepImg} alt="Jeep" />
                        </div>
                        <div>
                            <img src={dodgeImg} alt="Dodge" />
                        </div>
                        <div>
                            <img src={chryslerImg} alt="Chrysler" />
                        </div>
                    </div>
                    <div>
                        <img src={euroreparImg} alt="eurorepar" />
                    </div>
                </div>
                <div className="van">
                    <img src={vanImg} />
                    <img src={vanImg} />
                </div>
                <div className="sponsor-container">
                    <div>
                        <img src={purfluxImg} alt="purflux" />
                    </div>
                    <div>
                        <img src={febiImg} alt="febi" />
                    </div>
                    <div>
                        <img src={ferodoImg} alt="ferodo" />
                    </div>
                    <div>
                        <img src={seimImg} alt="seim" />
                    </div>
                    <div>
                        <img src={skfImg} alt="skf" />
                    </div>
                    <div>
                        <img src={juridImg} alt="jurid" />
                    </div>
                    <div>
                        <img src={ngkImg} alt="ngk" />
                    </div>
                    <div>
                        <img src={payenImg} alt="payen" />
                    </div>
                    <div>
                        <img src={blueprintImg} alt="blueprint" />
                    </div>
                    <div>
                        <img src={monroeImg} alt="monroe" />
                    </div>
                    <div>
                        <img id="valeo" src={valeoImg} alt="valeo" />
                    </div>
                    <div>
                        <img src={nissensImg} alt="nissens" />
                    </div>
                    <div>
                        <img src={lukLogo} alt="luk" />
                    </div>
                    <div>
                        <img src={snrImg} alt="snr" />
                    </div>
                </div>
                <div className="line-container orange">
                    <div className="orange-line"></div>
                </div>
            </section>
        </>
    );
}
