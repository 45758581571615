import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../Shared/IO/Input';
import apiService from '../../Shared/Service/api.service';
import { appActions } from '../../Store/app.actions';
import { TranslateContext } from '../../Store/translate.reducer';
import './ResetPassword.scss';

type Props = {
    initialValues: any;
};

export default function ResetPassword({ initialValues }: Props) {
    const t = useContext(TranslateContext);
    const { handleSubmit, register, errors } = useForm({
        defaultValues: initialValues,
    });

    const submit = (values: any) => {
        apiService
            .resetPassword({
                ...values,
                ...initialValues,
            })
            .then(() => {
                appActions.closeModal();
            });
    };

    return (
        <div className="reset-password-modal">
            <form onSubmit={handleSubmit(submit)}>
                <Input inputRef={register({ required: true })} disabled name="email" label={t.text('EMAIL')} />
                <Input
                    inputRef={register({ required: true })}
                    errors={errors}
                    type="password"
                    name="password"
                    label={t.text('NEW_PASSWORD')}
                />

                <div className="button-container">
                    <button type="submit">{t.text('SUBMIT')}</button>
                </div>
            </form>
        </div>
    );
}
