import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../Shared/IO/Input';
import apiService from '../../Shared/Service/api.service';
import { appActions } from '../../Store/app.actions';
import { TranslateContext } from '../../Store/translate.reducer';
import './ForgotPasswordModal.scss';

type Props = {
    initialValues: any;
};

export default function ForgotPasswordModal({ initialValues }: Props) {
    const t = useContext(TranslateContext);
    const { handleSubmit, register } = useForm({
        defaultValues: initialValues,
    });

    const submit = (values: any) => {
        apiService.sendForgotPasswordEmail(values.email, t.currentLang);
        appActions.closeModal();
    };

    return (
        <div className="forgot-password-modal">
            <form onSubmit={handleSubmit(submit)}>
                <Input inputRef={register({ required: true })} name="email" label={t.text('EMAIL')} />
                <div className="button-container">
                    <button type="submit">{t.text('SUBMIT')}</button>
                </div>
            </form>
        </div>
    );
}
