import React, { useContext, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { appActions } from '../../Store/app.actions';
import { AppContext } from '../../Store/app.reducer';
import { animationClassList, classN } from '../utils';
import FontIcon from './FontIcon';
import './SideMenu.scss';

export default function SideMenu() {
    const { sidemenu } = useContext(AppContext);
    const sideMenuRef = useRef<HTMLDivElement>(null);
    const nodeRef = useRef(null);

    useEffect(() => {
        if (sidemenu.active) {
            document.addEventListener('keydown', handleKeyPress);
        } else {
            document.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [sidemenu.active]);

    const handleKeyPress = (event: any) => event.key === 'Escape' && sidemenu.active && appActions.closeSidemenu();

    return (
        <CSSTransition
            in={sidemenu.active || false}
            timeout={400}
            nodeRef={nodeRef}
            unmountOnExit
            classNames={animationClassList('side')}
        >
            <div
                ref={nodeRef}
                className={classN('SideMenu', 'right')}
                // style={{
                //     zIndex: sidemenu.zIndex,
                // }}
            >
                <div className="background" onClick={appActions.closeSidemenu} />
                <div ref={sideMenuRef} className="menu">
                    <FontIcon className="close-icon" name="close" onClick={appActions.closeSidemenu} />
                    {sidemenu.content}
                </div>
            </div>
        </CSSTransition>
    );
}
