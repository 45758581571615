import React, { useContext } from 'react';
import blueprintImg from '../../assets/images/blueprint.png';
import febiImg from '../../assets/images/distrigo-psa-img-febi-1.png';
import juridImg from '../../assets/images/distrigo-psa-img-jurid.png';
import ngkImg from '../../assets/images/distrigo-psa-img-ngk.jpg';
import purfluxImg from '../../assets/images/distrigo-psa-img-purflux.png';
import seimImg from '../../assets/images/distrigo-psa-img-seim.png';
import skfImg from '../../assets/images/distrigo-psa-img-skf.png';
import ferodoImg from '../../assets/images/ferodo.png';
import lukLogo from '../../assets/images/LUK-Logo.jpg';
import monroeImg from '../../assets/images/monroe.png';
import nissensImg from '../../assets/images/nissens_logo_vece.jpg';
import payenImg from '../../assets/images/payen.png';
import snrImg from '../../assets/images/SNR.png';
import valeoImg from '../../assets/images/valeo.jpg';
import { TranslateContext } from '../../Store/translate.reducer';
import citroenImg from '../../assets/images/distrigo-psa-img-citroen.png';
import dsImg from '../../assets/images/distrigo-psa-img-ds.png';
import peugeotImg from '../../assets/images/logo-peugeot.png';
import opelImg from '../../assets/images/opel-Logo.png';
import './Sponsor.scss';

export default function Sponsor() {
    const t = useContext(TranslateContext);

    return (
        <section className="sponsor-section" id="sponsor">
            <div className="left">
                <div>
                    <h2> {t.text('BRAND_PARTS')} </h2>
                    <p>{t.text('MORE_THAN_25000_REF')}</p>
                </div>
            </div>
            <div className="right">
                <div className="sponsor">
                    <div>
                        <img className="small" src={peugeotImg} alt="peugeot" />
                    </div>
                    <div>
                        <img className="small" src={citroenImg} alt="citroen" />
                    </div>
                    <div>
                        <img className="small" src={dsImg} alt="ds" />
                    </div>
                    <div>
                        <img src={opelImg} alt="opel" />
                    </div>
                    <div>
                        <img src={purfluxImg} alt="purflux" />
                    </div>
                    <div>
                        <img src={febiImg} alt="febi" />
                    </div>
                    <div>
                        <img src={ferodoImg} alt="ferodo" />
                    </div>
                    <div>
                        <img src={seimImg} alt="seim" />
                    </div>
                    <div>
                        <img src={skfImg} alt="skf" />
                    </div>
                    <div>
                        <img src={juridImg} alt="jurid" />
                    </div>
                    <div>
                        <img src={ngkImg} alt="ngk" />
                    </div>
                    <div>
                        <img src={payenImg} alt="payen" />
                    </div>
                    <div>
                        <img src={blueprintImg} alt="blueprint" />
                    </div>
                    <div>
                        <img src={monroeImg} alt="monroe" />
                    </div>
                    <div>
                        <img id="valeo" src={valeoImg} alt="valeo" />
                    </div>
                    <div>
                        <img src={nissensImg} alt="nissens" />
                    </div>
                    <div>
                        <img src={lukLogo} alt="luk" />
                    </div>
                    <div>
                        <img src={snrImg} alt="snr" />
                    </div>
                </div>
            </div>
            <div className="line-container orange">
                <div className="orange-line"></div>
            </div>
        </section>
    );
}
