import React, { useContext } from 'react';
import { translateActions } from '../../Store/translate.actions';
import { TranslateContext } from '../../Store/translate.reducer';
import { UserContext } from '../../Store/user.reducer';
import { Lang } from '../../Types/lang';
import apiService from '../Service/api.service';
import { classN } from '../utils';
import './LangSelector.scss';

type Props = {};
export default function LangSelector({}: Props) {
    const t = useContext(TranslateContext);
    const { user } = useContext(UserContext);
    const changeLang = (lang: Lang) => () => {
        translateActions.changeLang(lang);
        if (user) {
            apiService
                .setUser(
                    {
                        id: user._id,
                        lang,
                    },
                    {
                        noSuccessFeedback: true,
                    }
                )
                .then(() => {});
        }
    };

    return (
        <div className="lang-selector">
            <span className={classN(t.currentLang === 'fr' && 'selected')} onClick={changeLang('fr')}>
                FR
            </span>
            /
            <span className={classN(t.currentLang === 'nl' && 'selected')} onClick={changeLang('nl')}>
                NL
            </span>
        </div>
    );
}
