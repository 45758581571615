import React, { useContext, useState } from 'react';
// import magasinImg from '../../assets/images/magasin.jpg';
import clarckImg from '../../assets/images/clark.jpg';
import { TranslateContext } from '../../Store/translate.reducer';
import './PSAis.scss';
import belgiumImg from '../../assets/images/belgium.png';
import FontIcon from '../../Shared/Component/FontIcon';
import video from '../../assets/vidéo-presentation.mp4';

export default function PSAis() {
    const t = useContext(TranslateContext);
    return (
        <section className="psa-is" id="psa-is">
            <div className="line-container blue">
                <div className="blue-line"></div>
            </div>
            <div className="left">
                <div className="video-container">
                    <video src={video} controls />{' '}
                </div>
            </div>
            <div className="right">
                <h2>{t.text('WHO_WE_ARE')}</h2>
                <p>{t.text('WHO_WE_ARE_1')}</p>
                <p>{t.text('WHO_WE_ARE_2')}</p>
                <p>{t.text('WHO_WE_ARE_3')}</p>
                <div>{t.text('WHO_WE_ARE_4')}</div>
                <div>- {t.text('WHO_WE_ARE_5')}</div>
                <div>- {t.text('WHO_WE_ARE_6')}</div>
                <div>- {t.text('WHO_WE_ARE_7')}</div>
            </div>
        </section>
    );
}
