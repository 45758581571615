import React from 'react';
import { DashboardInfo } from '../Types/dashboard';
import { ReducerAction } from '../Types/store';
import { appActionsName } from './app.actions';

export const appDefaultState = {
    modal: {
        active: false,
    },
    sidemenu: {
        active: false,
    },
    garages: null,
    dashboard: {
        carousel: [],
    },
    loading: true,
};
type AppContextType = {
    modal: {
        title?: string;
        active: boolean;
        content?: any;
        className?: string;
        size?: 'small';
    };
    sidemenu: {
        title?: string;
        active: boolean;
        content?: any;
    };
    dashboard: DashboardInfo;
    loading: boolean;
};

export const AppContext = React.createContext<AppContextType>(appDefaultState);

export const appReducer = (state: any, { type, payload }: ReducerAction) => {
    switch (type) {
        case appActionsName.loading:
            return {
                ...state,
                loading: payload,
            };
        case appActionsName.openModal:
            return {
                ...state,
                modal: {
                    ...payload,
                    active: true,
                },
            };
        case appActionsName.closeModal:
            return {
                ...state,
                modal: {
                    active: false,
                },
            };
        case appActionsName.openSidemenu:
            return {
                ...state,
                sidemenu: {
                    ...payload,
                    active: true,
                },
            };
        case appActionsName.closeSidemenu:
            return {
                ...state,
                sidemenu: {
                    active: false,
                },
            };

        case appActionsName.getDashboardInfo:
            return {
                ...state,
                dashboard: payload,
            };

        default:
            return state;
    }
};
