import React, { useContext } from 'react';
// import callcenterImg from '../../assets/images/distrigo-psa-img-bureau.png';
import { TranslateContext } from '../../Store/translate.reducer';

import { Link } from 'react-router-dom';
import CollaboratorCard from '../../Shared/Component/CollaboratorCard';
import FontIcon from '../../Shared/Component/FontIcon';
import { Team } from '../../Types/teams';
import './TeamSection.scss';

type Props = { teams: Team[] };
export default function TeamSection({ teams }: Props) {
    const t = useContext(TranslateContext);
    return (
        <section className="team-section" id="team-section">
            <div className="line-container blue">
                <div className="blue-line"></div>
            </div>
            <div className="left">
                <h2> {t.text('THE_TEAM')}</h2>
                <p>{t.text('OUR_MISSION_TEXT_2')}</p>
                <Link to="/team">
                    <button>
                        {t.text('ALL_TEAM')} <FontIcon className="icon-right" name="arrow-down" />
                    </button>
                </Link>
            </div>
            <div className="right">
                <div className="card-container">
                    {teams[0] &&
                        teams[0].collaborators.map((collaborator) => (
                            <CollaboratorCard key={collaborator._id} {...collaborator} />
                        ))}
                </div>
            </div>
        </section>
    );
}
