import React, { useContext, useEffect } from 'react';
import CollaboratorCard from '../../Shared/Component/CollaboratorCard';
import { appActions } from '../../Store/app.actions';
import { TranslateContext } from '../../Store/translate.reducer';
import { Team as TeamType } from '../../Types/teams';
import ContactForm from './Footer/ContactForm';
import './Team.scss';

type Props = { teams: TeamType[] };

export default function Team({ teams }: Props) {
    const t = useContext(TranslateContext);

    useEffect(() => {
        document.getElementById('wrapper')?.scrollTo?.(0, 0);
    }, []);
    const showContactForm = () => {
        appActions.openModal({
            size: 'medium',
            title: t.text('CONTACT_FORM'),
            content: <ContactForm />,
        });
    };
    return (
        <section id="team" className="team">
            <h2> {t.text('THE_TEAM')}</h2>

            {teams.map((team) => (
                <>
                    <h3>{team.name}</h3>
                    <div className="card-container" key={team._id}>
                        {team.collaborators.map((collaborator) => (
                            <CollaboratorCard big key={collaborator._id} {...collaborator} />
                        ))}
                    </div>
                </>
            ))}
        </section>
    );
}
