import moment from 'moment';
import { iNotification } from 'react-notifications-component';
import { translateInitData } from '../Store/translate.reducer';
import { NotifConfig } from '../Types/utils';

export const reducer = (prevState: any, updatedProperty: any) => {
    return {
        ...prevState,
        ...updatedProperty,
    };
};

export const classN = (...args: any[]) => {
    return args.reduce((acc, item) => (item ? `${acc ? acc + ' ' : acc}${item}` : acc), '');
};

export const notifConfig = (config: NotifConfig): iNotification => ({
    title: translateInitData.text(config.type === 'success' ? 'SUCCESS' : 'ERROR'),
    message: translateInitData.text(config.message),
    type: config.type,
    insert: 'bottom',
    container: 'bottom-left',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
        onScreen: true,
    },
});

export const animationClassList = (name: string) => ({
    appear: `${name}-appear`,
    appearActive: `${name}-appear-active`,
    enter: `${name}-enter`,
    enterActive: `${name}-enter-active`,
    enterDone: `${name}-enter-done`,
    exit: `${name}-exit`,
    exitActive: `${name}-exit-active`,
    exitDone: `${name}-exit-done`,
});

export const getQueryParams = (key: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key) || '';
};

export const setQueryParams = (key: string, value: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
};
export const validationMessage = {
    required: 'FIELD_REQUIRED',
    minLength: 'MIN_LENGTH_7',
    password_not_match: 'PASSWORD_NOT_MATCH',
};

export const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
export const email = (value: any) => (value && !emailRegex.test(value) ? 'INCORRECT_EMAIL' : true);

export function formatedDate(date: Date | number, lg: 'fr' | 'nl', format?: string) {
    return (
        date &&
        moment
            .utc(date)
            .local()
            .locale((lg || 'fr') as string)
            .format(format || 'DD/MM/YYYY')
    );
}

export function formatAmount(value: number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
}
/**
 * Download a file from an api
 */
export function downloadFile(data: any, filename: string, mime?: string) {
    const blob = new Blob([data], { type: mime || 'application/octet-stream' });
    const blobURL = window.URL.createObjectURL(blob);
    downloadBlobFile(blobURL, filename, blob);
    window.URL.revokeObjectURL(blobURL);
}

export function downloadBlobFile(blobURL: any, filename: any, blob?: any) {
    if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        if (blob) {
            (window.navigator as any).msSaveBlob(blob, filename);
        } else {
            fetch(blobURL)
                .then((res) => res.blob())
                .then((blob) => {
                    (window.navigator as any).msSaveBlob(blob, filename);
                });
        }
    } else {
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.

        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    }
}
