import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Reaptcha from 'reaptcha';
import Input from '../../../Shared/IO/Input';
import apiService from '../../../Shared/Service/api.service';
import { email } from '../../../Shared/utils';
import { appActions } from '../../../Store/app.actions';
import { TranslateContext } from '../../../Store/translate.reducer';
import './ContactForm.scss';

export default function ContactForm() {
    const t = useContext(TranslateContext);
    const { register, handleSubmit, errors } = useForm();
    const [captcha, setCaptcha] = useState(true);

    const submit = (value: any) => {
        apiService.sendContactEmail({ ...value, lang: t.currentLang }).then(() => {
            appActions.closeModal();
        });
    };
    const onVerify = () => {
        setCaptcha(true);
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit(submit)}>
            <Input label={t.text('NAME')} inputRef={register({ required: true })} name="name" errors={errors} />
            <Input
                label={t.text('EMAIL')}
                inputRef={register({
                    required: true,
                    validate: {
                        email,
                    },
                })}
                name="email"
                errors={errors}
            />
            {/* <Input label={t.text('PHONE')} inputRef={register({ required: true })} name="name" errors={errors} /> */}
            <div className="textarea">
                <textarea
                    ref={register({ required: true })}
                    rows={8}
                    placeholder={t.text('YOUR_MESSAGE')}
                    name="message"
                ></textarea>
                {errors['message'] && <span className="errors-input">{t.text('FIELD_REQUIRED')}</span>}
            </div>

            <Reaptcha sitekey="6Le9zrAdAAAAALvPeJcs8kh8CJINUGshyY_vByBC" onVerify={onVerify} />
            {/* <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LfK1zMaAAAAAKWgu2gNLIh57PzobjJ0yBdJh7mB" /> */}
            <button disabled={!captcha} type="submit">
                {t.text('SUBMIT')}
            </button>
        </form>
    );
}
