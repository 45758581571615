import React, { useContext, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import magasin2Img from '../assets/images/magasin2.jpeg';
import Header from '../Shared/Component/Header';
import Dropdown from '../Shared/IO/Dropdown';
import Input from '../Shared/IO/Input';
import apiService from '../Shared/Service/api.service';
import { email, getQueryParams } from '../Shared/utils';
import { appActions } from '../Store/app.actions';
import { TranslateContext } from '../Store/translate.reducer';
import { UserType } from '../Types/UserType';
import './Login.scss';
import ForgotPasswordModal from './Login/ForgotPasswordModal';
import ResetPassword from './Login/ResetPassword';

export default function Login() {
    const signupForm = useForm();
    const loginForm = useForm();
    const t = useContext(TranslateContext);
    const navigate = useNavigate();

    useEffect(() => {
        const resetPassword = getQueryParams('reset-password');
        if (resetPassword) {
            openResetPasswordModal(getQueryParams('token'));
        }
    }, []);

    const signupSubmit = (values: any) => {
        if (values.type?.description) {
            values.type = values.type.value;
        }
        apiService.createUser({ ...values, lang: t.currentLang }).then((value) => {
            signupForm.reset();
        });
    };

    const loginSubmit = (values: any) => {
        apiService
            .login(values)
            .then(() => {
                navigate('/app');
            })
            .catch(() => {});
    };

    const openResetPasswordModal = (token: string) => {
        appActions.openModal({
            title: t.text('RESET_PASSWORD'),
            size: 'medium',
            content: <ResetPassword initialValues={{ email: getQueryParams('email'), token }} />,
        });
    };
    const openForgotPasswordModal = () => {
        appActions.openModal({
            title: t.text('PASSWORD_FORGOTTEN'),
            size: 'medium',
            content: <ForgotPasswordModal initialValues={{ email: loginForm.getValues().email }} />,
        });
    };

    const password = signupForm.watch('password');
    return (
        <div
            className="login"
            style={{
                backgroundImage: `url(${magasin2Img})`,
            }}
        >
            <Header />
            <div className="login-wrapper">
                <div className="container">
                    <h2>{t.text('CONNECTION')}</h2>
                    <FormProvider {...loginForm}>
                        <form onSubmit={loginForm.handleSubmit(loginSubmit)}>
                            <Input
                                inputRef={loginForm.register({ required: true })}
                                name="email"
                                label={t.text('EMAIL')}
                                errors={loginForm.errors}
                            />
                            <Input
                                inputRef={loginForm.register({ required: true, minLength: 6 })}
                                name="password"
                                type="password"
                                label={t.text('PASSWORD')}
                                errors={loginForm.errors}
                            />
                            <div className="button-container">
                                <div className="second-action" onClick={openForgotPasswordModal}>
                                    {t.text('PASSWORD_FORGOTTEN')}
                                </div>
                                <button type="submit"> {t.text('LOGIN')}</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                <div className="container">
                    <h2>{t.text('INSCRIPTION')}</h2>
                    <FormProvider {...signupForm}>
                        <form onSubmit={signupForm.handleSubmit(signupSubmit)}>
                            <Input
                                inputRef={signupForm.register({ required: true })}
                                name="name"
                                label={t.text('GARAGE')}
                                errors={signupForm.errors}
                            />

                            <Input
                                inputRef={signupForm.register({ required: true, validate: email })}
                                name="email"
                                label={t.text('EMAIL')}
                                errors={signupForm.errors}
                            />

                            <Input
                                inputRef={signupForm.register({ required: true, minLength: 7 })}
                                name="password"
                                type="password"
                                label={t.text('PASSWORD')}
                                errors={signupForm.errors}
                            />
                            <Input
                                inputRef={signupForm.register({
                                    required: true,
                                    validate: (value) => (password === value ? true : 'PASSWORD_NOT_MATCH'),
                                })}
                                name="confirm-password"
                                type="password"
                                label={t.text('PASSWORD')}
                                errors={signupForm.errors}
                            />

                            <Controller
                                as={Dropdown}
                                defaultValue=""
                                label={t.text('TYPE')}
                                noMargin
                                itemRendering={({ item }: any) => (
                                    <div>
                                        <div>{item?.description}</div>
                                        <div className="item-type-subtitle">{item?.subtitle}</div>
                                    </div>
                                )}
                                items={[
                                    {
                                        description: 'ERCS',
                                        subtitle: t.text('ERCS'),
                                        value: UserType.ERCS,
                                    },
                                    {
                                        description: 'RA',
                                        subtitle: t.text('RA'),
                                        value: UserType.RA,
                                    },
                                    {
                                        description: 'RI',
                                        subtitle: t.text('RI'),
                                        value: UserType.RI,
                                    },
                                    { description: t.text('OTHERS'), subtitle: '', value: UserType.NONE },
                                ]}
                                name="type"
                                keyText="description"
                                keyValue="value"
                                rules={{ required: true }}
                            />
                            <div className="button-container">
                                <button type="submit">{t.text('SIGNUP')}</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    );
}
