let instance : LocalStorageService;
class LocalStorageService {
  static getInstance() {
    if (instance) {
      return instance;
    } else {
      return (instance = new LocalStorageService());
    }
  }

  getToken = () => {
    return this.tryRetrieve("token")
  }

  setToken = (token : string) => {
    this.store("token", token);
  }

  getUser = () => {
    return this.tryRetrieve("user");
  };

  setUser = (user: string) => {
    this.store("user", user);
  }

  tryRetrieve = (key : string) => {
    let result = null;
    try {
      let data = localStorage.getItem(key);
      result = JSON.parse(data as any);
    } catch (e) {
      return null;
    }
    return result;
  };

  store = (key :string, data : any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
}

export default LocalStorageService.getInstance();
