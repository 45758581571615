import React, { useContext } from 'react';
import { TranslateContext } from '../../Store/translate.reducer';

import './WhoWeAre.scss';

export default function WhoWeAre() {
    const t = useContext(TranslateContext);
    return (
        <section className="who-we-are" id="who-we-are">
            <div className="left">
                <h2> {t.text('OUR_MISSION')}</h2>
                <p>{t.text('OUR_MISSION_TEXT_1')}</p>
                <p>{t.text('OUR_MISSION_TEXT_2')}</p>
                <p>{t.text('OUR_MISSION_TEXT_3')}</p>
            </div>
            <div className="right"></div>
            <div className="line-container orange">
                <div className="orange-line"></div>
            </div>
        </section>
    );
}
