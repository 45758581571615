import React, { useContext } from 'react';
import { TranslateContext } from '../../Store/translate.reducer';
import { classN, validationMessage } from '../utils';
import './Input.scss';

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    label: string;
    inputRef?: any;
    errors?: any;
};
export default function MyDistrigo({ label, inputRef, errors, ...props }: Props) {
    const t = useContext(TranslateContext);
    return (
        <div className={classN('input', errors && errors[props.name as any] && 'error')}>
            <label>{label}</label>
            <div>
                <input ref={inputRef} {...props} />
                {errors && errors[props.name as any] && (
                    <span className="errors-input">
                        {t.text(
                            errors[props.name as any].type === 'validate'
                                ? errors[props.name as any].message
                                : (validationMessage as any)[errors[props.name as any].type]
                        )}
                    </span>
                )}
            </div>
        </div>
    );
}
