import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [17, 46],
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Map() {
    const [position] = useState({
        lat: 50.886011211097305,
        lng: 4.390536269282795,
    });

    useEffect(() => {
        return;
    }, []);
    return (
        <MapContainer center={[position.lat, position.lng]} zoom={13} style={{ height: '400px' }}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[position.lat, position.lng]}>
                <Popup>Stellantis & You Distrigo Brussels </Popup>
            </Marker>
        </MapContainer>
    );
}
