type UserActionsType = {
    login: (user: any) => void;
    logout: () => void;
    createUser: (user: any) => void;
    getUser: (user: any) => void;
};

export const userActionsName = {
    createUser: 'CREATE_USER',
    getUser: 'GET_USER',
    login: 'LOGIN',
    logout: 'LOGOUT',
};

let dispatch: any = null;

export const setUserActionDispatch = (newDispatch: Function) => {
    dispatch = newDispatch;
};

export const userActions: UserActionsType = {} as UserActionsType;
for (const action in userActionsName) {
    (userActions as any)[action] = (payload: any) => {
        dispatch!({
            type: (userActionsName as any)[action],
            payload: payload,
        });
    };
}
