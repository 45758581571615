import React, { ChangeEvent, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
// import InfiniteScroll from "shared/components/InfiniteScroll";
import { TranslateContext } from '../../Store/translate.reducer';
import FontIcon from '../Component/FontIcon';
import { useCombinedRefs, usePrevious } from '../hook';
import { classN, reducer, validationMessage } from '../utils';
import './Dropdown.scss';
// import { validationMessage } from "shared/utils/validation";

export type CustomSelectProps = {
    noTags?: boolean;
    defaultValue?: any;
    customInputClassname?: string;
    name: string;
    label?: string;
    info?: string;
    withBorder?: boolean;
    noError?: boolean;
    items: any[];
    multiple?: boolean;
    filter?: boolean;
    className?: string;
    placeholder?: string;
    valueClassName?: string;
    keyText?: string;
    keyValue?: string;
    noBorder?: boolean;
    noMargin?: boolean;
    noSuggestions?: boolean;
    noArrow?: boolean;
    infiniteScroll?: boolean;
    shadow?: boolean;
    white?: boolean;
    selectClassName?: string;
    itemsClassName?: string;
    size?: string;
    tabIndex?: number;
    listClassName?: string;
    errorClassName?: string;
    placeholderFilter?: string;
    removeAll?: string;
    debounce?: number;
    itemRendering?: Function;
    valueRendering?: Function;
    load?: Function;
    onChange?: (value: any) => void;
    onSelectItem?: Function;
    onInputChange?: Function;
    CustomInput?: Function;
    canAdd?: boolean;
    treatValueOnAdd?: Function;
    inputValidation?: Function;
    onValueChanged?: (value: any) => void;
    refMulipleInput?: any;
};

let tmpCursor: any;

export default function Dropdown(props: CustomSelectProps) {
    const {
        defaultValue,
        infiniteScroll,
        items,
        load,
        debounce,
        multiple,
        keyValue,
        label,
        info,
        filter,
        placeholderFilter,
        className,
        noArrow,
        size,
        withBorder,
        noBorder,
        noMargin,
        shadow,
        valueRendering,
        itemRendering,
        selectClassName,
        placeholder,
        itemsClassName,
        keyText,
        listClassName,
        tabIndex,
        white,
        CustomInput,
        customInputClassname,
        valueClassName,
        errorClassName,
        noError,
        onChange,
        onSelectItem,
        onInputChange,
        canAdd,
        name,
        removeAll,
        treatValueOnAdd,
        inputValidation,
        noTags,
        onValueChanged,
        noSuggestions,
        refMulipleInput,
        ...rest
    } = props;

    type ListItem = {
        text: string;
        value: string;
        item: any;
    };

    const treatList = (items: any[], filterValue?: any): any => {
        return filterValue
            ? (items || []).filter(
                  (it: any) =>
                      (it[keyText as any] || it)
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .indexOf(
                              filterValue
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                          ) !== -1
              )
            : items || [];
    };

    const t = useContext(TranslateContext);
    const [state, setState] = useReducer(reducer, {
        value: {
            text: '',
            value: multiple ? [] : null,
        },
        dropdownActive: false,
        cursor: -1,
        list: treatList(items),
        filter: null,
    });
    const { value, dropdownActive, cursor, loading, filterValue, list } = state;
    const prevState = usePrevious(state);

    const [timeoutDebounce, setTimeoutDebounce] = useState();
    const { errors, watch, setValue, setError, clearErrors } = useFormContext();
    const error = !noError && (get(errors, name) || get(errors, name + 'input'));
    const inputVal = watch(name) || '';
    useEffect(() => {
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [state.dropdownActive]);

    useEffect(() => {
        if (!multiple) {
            handleValue();
        }
    }, [state.list]);

    useEffect(() => {
        handleValue();
    }, [inputVal]);

    useEffect(() => {
        handleItems();
    }, [items, state.filterValue]);

    useEffect(() => {
        if (
            load &&
            dropdownActive &&
            (filter
                ? prevState.filterValue !== state.filterValue || !prevState.dropdownActive
                : prevState.value.text !== state.value.text || !prevState.dropdownActive)
        ) {
            getList(filter ? state.filterValue : value.text);
        }
    }, [state.dropdownActive, state.value, state.filterValue]);

    useEffect(() => {
        if (dropdownActive) {
            tmpCursor = cursor;
            document.addEventListener('keydown', handleKey);
            document.addEventListener('mouseup', handleClickOutside);
        } else {
            document.removeEventListener('keydown', handleKey);
            document.removeEventListener('mouseup', handleClickOutside);
        }

        return () => {
            document.removeEventListener('keydown', handleKey);
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [state.dropdownActive, inputVal]);

    const node = useRef<HTMLDivElement>(null);
    const filterRef = useRef<HTMLInputElement>(null);
    const multipleInputRef = useRef<HTMLDivElement>(null);
    const customInputRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLDivElement>(null);

    const combinedRefs = useCombinedRefs(refMulipleInput, multipleInputRef);

    const handleKey = (event: KeyboardEvent) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            tmpCursor = tmpCursor !== items.length - 1 ? tmpCursor + 1 : items.length - 1;
            if (tmpCursor > 0) {
                itemsRef.current!.scrollTop = itemsRef.current!.scrollTop + 47;
            }
            setState({
                cursor: tmpCursor,
            });
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();

            tmpCursor = tmpCursor !== (removeAll ? -1 : 0) ? tmpCursor - 1 : tmpCursor;
            if (tmpCursor < items.length - 1) {
                itemsRef.current!.scrollTop = itemsRef.current!.scrollTop - 47;
            }
            setState({
                cursor: tmpCursor,
            });
        } else if (event.key === 'Enter') {
            event.preventDefault();
            removeAll && tmpCursor === -1 ? selectValue([] as any) : tmpCursor >= 0 && selectItem(list[tmpCursor]);
        } else if (event.key === 'Escape') {
            toggleClick(false);
        }
    };

    const getList = (valueToSearch: any) => {
        if (load && dropdownActive) {
            if (!items || (items.length === 0 && valueToSearch?.text)) {
                setState({
                    loading: true,
                });
            }
            if (timeoutDebounce) clearTimeout(timeoutDebounce);
            const timeout = setTimeout((): void => load(valueToSearch), debounce || 500);
            setTimeoutDebounce(timeout as any);
        }
    };

    const handleClickOutside = (event: Event) => {
        if (dropdownActive && !node?.current!.contains(event.target as any)) {
            toggleClick(false);
        }
    };

    const handleItems = () => {
        const newItems = treatList(items, filterValue);

        setState({
            loading: false,
            list: newItems.map((it: any) => ({
                text: it[keyText as any] || it,
                value: it[keyValue as any] || it,
                item: it,
            })),
        });
    };

    const compareItem = (i1: any, i2: ListItem) => (i1.value ? i1.value === i2.value : i1 === i2.value);

    const handleValue = (val?: any) => {
        let stateValue: any;
        let newValue = val || inputVal;

        if (multiple) {
            stateValue = [];
            if (!canAdd) {
                for (const item of list) {
                    for (const inputItem of newValue) {
                        if (compareItem(inputItem, item)) {
                            stateValue.push(item);
                        }
                    }
                }
            } else {
                stateValue = newValue || [];
            }

            setState({
                value: {
                    value: stateValue,
                    text: value?.text || '',
                },
            });
        } else {
            stateValue = list.find((item: any) => compareItem(newValue, item));

            const newStateValue = stateValue || {
                text: (keyText && newValue && newValue[keyText]) || newValue,
                value: (keyValue && newValue && newValue[keyValue]) || newValue,
            };
            if ((!CustomInput && stateValue) || !(CustomInput && newValue && newStateValue?.text === newValue.text)) {
                setState({
                    value: newStateValue,
                });
            }
        }
    };

    const hasValue = () => (multiple ? inputVal?.length > 0 : !!inputVal);

    const toggleClick = (forcedValue?: boolean) => {
        const newDropdownActive = typeof forcedValue === 'boolean' ? forcedValue : !dropdownActive;

        if (newDropdownActive) {
            if (filter) {
                filterRef.current!.focus();
            }
            if (CustomInput) {
                customInputRef.current!.focus();
            } else if (multiple && !valueRendering && canAdd) {
                multipleInputRef.current!.focus();
            }
        }

        setState({
            dropdownActive: newDropdownActive,
        });
    };

    const editValue = (value: any) => {
        setValue(name, value);
        onValueChanged && onValueChanged(value);
        onChange && onChange(value);
    };

    const selectValue = (item: ListItem) => {
        const cursorPlace = list.findIndex((it: ListItem) => it.value === item.value);

        if (multiple) {
            const current: any[] = Array.isArray(inputVal) ? inputVal.slice() : inputVal ? [inputVal] : [];
            const foundItemIndex = current.findIndex((it) => it === item.value);
            if (foundItemIndex !== -1) {
                current.splice(foundItemIndex, 1);
                editValue([...current]);
            } else {
                const newValue = treatValueOnAdd ? treatValueOnAdd(item) : item.value || item;

                current.push(newValue);
                editValue([...current]);
                setState({
                    value: {
                        ...value,
                        text: '',
                    },
                });
            }
        } else {
            if (inputVal === item?.value || inputVal === item) {
                editValue(null);
                setState({ cursor: -1 });
            } else {
                editValue(item.value);
                setState({ cursor: cursorPlace });
            }
            toggleClick(false);
        }
    };

    const onMultipleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            value: { ...value, text: e.target.value },
        });
    };
    const addTag = () => {
        const inputError = inputValidation ? inputValidation(value.text) : false;
        // input.onBlur();
        if (inputError && inputError !== true) {
            setError(name, { type: 'custom', message: inputError });
        } else {
            clearErrors(name);
            selectValue(value.text);
            setState({
                errors: null,
            });
        }
    };
    const removeTags = (index: number) => {
        const newValue = value.value;
        newValue.splice(index, 1);
        editValue(newValue);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        const text = value?.text.trim();
        if (e.key === 'Enter' || e.keyCode === 32) {
            e.preventDefault();
            text && addTag();
        }
    };

    const checkItem = (item: ListItem) =>
        multiple ? value.value.find((it: ListItem) => compareItem(it, item)) : compareItem(value, item);

    const selectItem = (item: ListItem) => {
        selectValue(item);
        onSelectItem && onSelectItem(item);
    };
    const onFilterChange = (e: any) => {
        const value = e.currentTarget.value;

        setState({
            filterValue: value,
        });
    };
    const onCustomInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(name, e.target.value);
        onInputChange && onInputChange(e.target.value);
    };

    return (
        <div
            ref={node}
            tabIndex={0}
            onFocus={(e) => {
                e.relatedTarget && toggleClick(true);
            }}
            onBlur={(e) => {
                toggleClick(false);
            }}
            className={classN(
                'Dropdown',
                selectClassName,
                withBorder && 'with-border',
                dropdownActive && 'active',
                noMargin && 'no-margin',
                'normal',
                shadow && 'shadow',
                noArrow && 'no-arrow',
                error && 'error'
            )}
        >
            <label>{label}</label>
            <div
                className={classN(
                    'input-wrapper',
                    'with-icon',
                    className,
                    white && 'white',
                    'normal',
                    !noBorder && 'bordered',
                    withBorder && 'with-border',
                    shadow && 'shadow',
                    error && 'error',
                    error && errorClassName,
                    noMargin && 'no-margin',
                    hasValue() && 'visited'
                )}
            >
                <div
                    onMouseDown={(e) => {
                        e.preventDefault();
                        toggleClick();
                    }}
                    className={classN('value', multiple && 'multiple', valueClassName)}
                >
                    {CustomInput ? (
                        <CustomInput
                            {...rest}
                            inputRef={customInputRef}
                            noMargin={true}
                            noBorder={true}
                            noError
                            className={classN('custom-input', customInputClassname)}
                            placeholder={placeholder}
                            size={size}
                            name={name}
                            onChange={onCustomInputChange}
                            autoComplete="off"
                            value={value ? value.text : ''}
                        />
                    ) : valueRendering ? (
                        valueRendering(value)
                    ) : multiple ? (
                        canAdd ? (
                            <input
                                ref={combinedRefs}
                                onKeyDown={handleKeyDown as any}
                                name={name + 'input'}
                                placeholder={placeholder}
                                value={value?.text || ''}
                                onChange={onMultipleTextChanged}
                            />
                        ) : (
                            <span className={classN('placeholder')}>
                                {!noTags && (
                                    <div className={classN('multiple')}>
                                        <div className={classN('values-wrapper')}>
                                            {value?.value.map((it: any, index: number) => (
                                                <div key={index}>
                                                    <div>
                                                        <span>{it?.text || it[keyText!] || it}</span>
                                                        {/* <FontIcon
                    title={t.text("COMMON.DELETE")}
                    onClick={() => removeTags(index)}
                    name="small-remove"
                  /> */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </span>
                        )
                    ) : !value || !(value.value || value.text) ? (
                        <span className={classN('placeholder')}>{placeholder}</span>
                    ) : (
                        state.value.text
                    )}
                </div>
                {!noSuggestions && (
                    <div
                        className={classN('items', size, itemsClassName, {
                            'with-filter': filter,
                        })}
                    >
                        {filter && (
                            <input
                                ref={filterRef}
                                tabIndex={tabIndex}
                                className={classN('filter')}
                                name="filter_input"
                                placeholder={placeholderFilter || 'filtrer...'}
                                autoComplete="off"
                                onChange={onFilterChange}
                            />
                        )}
                        <div ref={itemsRef} className={classN('items-wrapper', listClassName)}>
                            {removeAll && (
                                <div className={classN('item')} onClick={() => editValue(multiple ? [] : '')}>
                                    {removeAll}
                                </div>
                            )}
                            {list.map((item: any, key: any) => {
                                return (
                                    <div
                                        className={classN(
                                            'item',
                                            checkItem(item) && 'active',
                                            cursor === key && 'hoverArrow'
                                        )}
                                        key={item.value}
                                        onClick={() => selectItem(item)}
                                    >
                                        {itemRendering ? itemRendering(item) : item.text}
                                    </div>
                                );
                            })}
                        </div>

                        {loading ? (
                            <div className={classN('item')}>{t.text('LOADING')}</div>
                        ) : (
                            list.length === 0 && <div className={classN('item')}>{t.text('NO_RESULT')}</div>
                        )}
                    </div>
                )}
                <div className={classN('icon', 'arrow')}>
                    {multiple &&
                        value.value &&
                        (value.text ? (
                            <FontIcon onClick={addTag} className={classN('add-icon')} name="plus" />
                        ) : (
                            value.value.length > 1 && <div className={classN('indic')}>{value.value.length}</div>
                        ))}
                    {!noArrow && (
                        <FontIcon
                            onMouseDown={(e) => {
                                e.preventDefault();
                                toggleClick();
                            }}
                            className={classN('arrow-icon')}
                            name="arrow-down"
                        />
                    )}
                </div>
                {error && (
                    <div className="errors-input">
                        {t.text(error.message || (validationMessage as any)[error.type])}
                    </div>
                )}
            </div>
        </div>
    );
}
