import React, { useContext } from 'react';
import { BounceLoader } from 'react-spinners';
import { AppContext } from '../../Store/app.reducer';
import './Loading.scss';

export default function Loading() {
    const { loading } = useContext(AppContext);

    return loading ? (
        <div className="loading">
            <BounceLoader color="#ed5929" />
        </div>
    ) : null;
}
