import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import Header from '../Shared/Component/Header';
import apiService from '../Shared/Service/api.service';
import { TranslateContext } from '../Store/translate.reducer';
import { Team as TeamType } from '../Types/teams';
import './LandingPage.scss';
import Footer from './LandingPage/Footer';
import MainSection from './LandingPage/MainSection';
import Map from './LandingPage/Map';
import Parternship from './LandingPage/Parternship';
import PSAis from './LandingPage/PSAis';
import SecondSection from './LandingPage/SecondSection';
import Sponsor from './LandingPage/Sponsor';
import Team from './LandingPage/Team';
import TeamSection from './LandingPage/TeamSection';
import WhoWeAre from './LandingPage/WhoWeAre';

export default function LandingPage() {
    const t = useContext(TranslateContext);
    const contentWrapper = useRef<HTMLDivElement>(null);
    const [teams, setTeams] = useState<TeamType[]>([]);

    useEffect(() => {
        apiService.getCollaboratorsTeam().then((collaboratorsTeams) => {
            setTeams(collaboratorsTeams);
        });
    }, []);

    return (
        <div className="landing-page">
            <Header />
            <div ref={contentWrapper} className="content-wrapper" id="wrapper">
                <Routes>
                    <Route path="/team" element={<Team teams={teams} />} />
                    <Route
                        path="*"
                        element={
                            <>
                                <MainSection />
                                <PSAis />
                                <WhoWeAre />
                                <SecondSection />
                                <Sponsor />
                                <TeamSection teams={teams} />
                                <section className="map" id="map">
                                    <Map />
                                </section>
                                <Parternship />
                            </>
                        }
                    />
                </Routes>

                <Footer />
            </div>
        </div>
    );
}
