import firebase from 'firebase/app';
import 'firebase/storage';

const config = {
    apiKey: 'AIzaSyBURDmviUR9S8BMxYpx63B0HZbl1Ms_gw8',
    authDomain: 'our-foundry-289307.firebaseapp.com',
    projectId: 'our-foundry-289307',
    storageBucket: 'our-foundry-289307.appspot.com',
    messagingSenderId: '507971009437',
    appId: '1:507971009437:web:201196cc0442ac8b59cd74',
    measurementId: 'G-BL8MDY8EWT',
};
export let FirebaseApp: firebase.app.App;

const initializeFirebaseApp = () => {
    FirebaseApp = firebase.initializeApp(config);
};

let instance: StorageFirebase;

let storageRef: firebase.storage.Reference | null = null;

class StorageFirebase {
    static getInstance() {
        if (instance) return instance;
        initializeFirebaseApp();
        storageRef = firebase.storage().ref();
        instance = new StorageFirebase();
        return instance;
    }

    // deleteFile(fileName: string) {
    //     return this.storageRef.child(fileName).delete();
    // }
    uploadFile(path: string, file: File) {
        return storageRef!.child(`${process.env.NODE_ENV === 'development' ? 'DEV/' : ''}${path}`).put(file);
    }
    downloadFile(url: string) {
        return storageRef!.child(url).getDownloadURL();
    }
}

export default StorageFirebase.getInstance();
