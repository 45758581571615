import { Store } from 'react-notifications-component';
import { appActions } from '../../Store/app.actions';
import { userActions } from '../../Store/user.actions';
import { UserRole } from '../../Types/UserRole';
import { UserType } from '../../Types/UserType';
import { ReqOptions, ResData } from '../../Types/api';
import { Collaborator } from '../../Types/collaborator';
import { DashboardInfo } from '../../Types/dashboard';
import { Element } from '../../Types/element';
import { ElementType } from '../../Types/element-type';
import { Lang } from '../../Types/lang';
import { Order, Product, UserOrder } from '../../Types/order';
import { Supplier } from '../../Types/supplier';
import { Team } from '../../Types/teams';
import { UsefullLink } from '../../Types/usefull-link';
import { User } from '../../Types/user';
import { notifConfig } from '../utils';
import StorageFirebase from './StorageFirebase';
let instance: ApiService;

function getInstance() {
    if (instance) {
        return instance;
    } else {
        instance = new ApiService();
        return instance;
    }
}

class ApiService {
    // baseUrl: string;
    userToken: any;
    baseRoot =
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:8080/'
            : 'https://our-foundry-289307.ew.r.appspot.com/';
    headers = new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    });

    createHeaders(token?: string) {
        this.headers = new Headers({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });
    }

    setUserToken(token: string) {
        this.userToken = token;
        this.createHeaders(token);
    }

    response(promise: Promise<any>, { noSuccessFeedback, noFeedBack, noLoading }: any = {}): Promise<ResData> {
        !noLoading && appActions.loading(true);

        return new Promise((resolve, reject) => {
            promise
                .then((res) => {
                    if (res.status >= 400) {
                        res.json().then((response: any) => {
                            if (res.status === 400) {
                                !noFeedBack &&
                                    Store.addNotification(
                                        notifConfig({
                                            message: response.message,
                                            type: response.type,
                                        })
                                    );
                                throw reject(response);
                            } else {
                                !noFeedBack &&
                                    Store.addNotification(
                                        notifConfig({
                                            message: response.message,
                                            type: response.type,
                                        })
                                    );
                                return reject(res);
                            }
                        });
                    } else if (res.status === 200) {
                        res.json().then((response: any) => {
                            !noSuccessFeedback &&
                                Store.addNotification(
                                    notifConfig({
                                        message: response.message,
                                        type: response.type,
                                    })
                                );
                            resolve(response);
                        });
                    } else {
                        return res.json();
                    }
                })
                .catch((e) => {
                    Store.addNotification(
                        notifConfig({
                            message: 'SERVER_NOT_RESPONDING',
                            type: 'danger',
                        })
                    );
                })
                .finally(() => {
                    !noLoading && appActions.loading(false);
                });
        });
    }

    get<T>(url: string, reqOptions: ReqOptions = { noSuccessFeedback: true }) {
        return this.response(fetch(this.baseRoot + url, { headers: this.headers }), reqOptions).then<T>(
            (res) => res as any
        );
    }
    post(url: string, req: any, reqOptions?: ReqOptions) {
        return this.response(
            fetch(this.baseRoot + url, {
                method: 'POST',
                body: JSON.stringify(req),
                headers: this.headers,
            }),
            reqOptions
        );
    }
    download(url: string, fileName: string) {
        return fetch(this.baseRoot + url, {
            method: 'GET',
            headers: {
                // 'Content-type': 'application/pdf'
                Authorization: `Bearer ${this.userToken}`,
            },
        })
            .then((res) => (res.status >= 400 ? res.json() : res.arrayBuffer()))
            .then((file) => {
                if (file.type) {
                    Store.addNotification(
                        notifConfig({
                            message: file.message,
                            type: file.type,
                        })
                    );
                } else if (file) {
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    const newBlob = new Blob([file as any]);
                    // const pdfFile = atob(file as any);
                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(newBlob);
                        return;
                    }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob);
                    const link = document.createElement('a');
                    link.href = data;
                    link.download = fileName;
                    link.click();
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data);
                    }, 100);
                } else {
                }
            })
            .catch(() => {
                appActions.loading(false);
            });
    }
    put(url: string, id: string, req: any, reqOptions?: ReqOptions) {
        return this.response(
            fetch(`${this.baseRoot}${url}/${id} `, {
                method: 'PUT',
                body: JSON.stringify(req),
                headers: this.headers,
            }),
            reqOptions
        );
    }
    delete(url: string, id: string, reqOptions?: ReqOptions) {
        return this.response(
            fetch(`${this.baseRoot + url}/${id}`, {
                method: 'DELETE',
                headers: this.headers,
            }),
            reqOptions
        );
    }

    sendFile(file: any, body: any, method: any, url: string, reqOptions?: ReqOptions) {
        const formData = new FormData();
        formData.append('file', file);

        const data: any = {};
        data.extension = file?.name?.substring(file!.name.lastIndexOf('.') + 1);
        for (const key in body) {
            if (key !== 'file') {
                data[key] = (body as any)[key];
            }
        }
        formData.append('data', JSON.stringify(data));
        return this.response(
            fetch(this.baseRoot + url, {
                method,
                body: formData,
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.userToken}`,
                }),
            }),
            reqOptions
        );
    }

    getDashboardInfo() {
        this.get<DashboardInfo>('api/dashboard').then((data) => {
            appActions.getDashboardInfo(data);
        });
    }
    addCarouselImage(file: any) {
        return this.sendFile(file, {}, 'POST', `api/carousel`);
    }
    setCarousel(elements: any) {
        return this.put(`api/app`, 'carousel', elements);
    }
    deleteCarouselImage(id: any) {
        return this.delete(`api/carousel`, id);
    }

    ///// USER

    login(user: User) {
        return this.post('api/users/login', user, { noSuccessFeedback: true }).then((user: any) => {
            user.unseenElId = [];
            user.unseenEl.forEach((el: any) => {
                user.unseenElId.push(el.elementId);
                el.parentId && user.unseenElId.push(el.parentId);
                el.folderId && user.unseenElId.push(el.folderId);
            });
            userActions.login(user);
        });
    }
    getCurrentUser(noFeedback?: boolean) {
        return this.get<any>('api/users/current', { noFeedback, noSuccessFeedback: true }).then((user) => {
            user.unseenElId = [];
            user.unseenEl.forEach((el: any) => {
                user.unseenElId.push(el.elementId);
                el.parentId && user.unseenElId.push(el.parentId);
                el.folderId && user.unseenElId.push(el.folderId);
            });
            userActions.getUser(user);
        });
    }
    getUsersStatistics(params: any) {
        return this.get<any>(
            'api/users/financial-data?' +
                Object.keys(params).reduce((acc, param) => `${acc}${param}=${params[param]}&`, '')
        );
    }
    getUserStatistics(id: any, params: any) {
        return this.get<any>(
            `api/users/${id}/financial-data?` +
                Object.keys(params).reduce((acc, param) => `${acc}${param}=${params[param]}&`, '')
        );
    }
    getDataStatistics(params: any) {
        return this.get<any>(
            `api/users/export-financial-data?` +
                Object.keys(params).reduce((acc, param) => `${acc}${param}=${params[param]}&`, '')
        );
    }
    sendForgotPasswordEmail(email: string, lang: string) {
        return this.post('api/users/send-forgot-password-email', { email, lang });
    }
    resetPassword(value: any) {
        return this.post('api/users/reset-password', value);
    }
    sendActivationEmail(email: string) {
        this.post('api/users/send-activate-email', { email });
    }
    createUser(user: User) {
        return this.post('api/users', user);
    }
    setUser({ id, ...user }: { id: string; role?: UserRole; type?: UserType; lang?: Lang }, reqOptions?: ReqOptions) {
        return this.put('api/users', id, { user }, reqOptions);
    }
    getUsers() {
        return this.get<User[]>('api/users');
    }
    getUsersFilter() {
        return this.get<User[]>('api/users/filters');
    }

    //// Element
    getElements(folder?: string, child?: string) {
        return this.get<{ elements: Element[]; parent: Element; child: Element }>(
            `api/element?folder=${folder || ''}&child=${child || ''}`
        );
    }
    getElementsPromo() {
        return this.get<{ elements: Element[] }>(`api/element/promo`);
    }
    createElement({ file, ...element }: Element): any {
        return this.post('api/element', element, {
            noSuccessFeedback: element.type == ElementType.PDF_FILE,
            noLoading: true,
        });
    }

    setElement(id: string, element: Element) {
        return this.put('api/element', id, element);
    }
    setElements(element: Element[]) {
        return this.put('api', 'element', element);
    }
    deleteElement(id: string, reqOptions?: ReqOptions) {
        return this.delete(`api/element`, id, reqOptions);
    }
    downloadElementFile(element: Element) {
        return StorageFirebase.downloadFile(
            `${process.env.NODE_ENV === 'development' ? 'DEV/' : ''}${element.filepath}`
        );
        // return this.download(`api/element/${element._id!}/download-file`, `${element._id}.${element.extension}`);
    }
    uploadElementFile(id: any, guid: string, index: number, file: File) {
        return this.sendFile(file, { id, guid, index }, 'POST', `api/element/download-chuck`, {
            noSuccessFeedback: true,
            noLoading: true,
        });
    }
    markElementAsRead(elementId: string) {
        return this.post('api/element/mark-as-read', { elementId }, { noFeedback: true });
    }

    uploadDone(data: any) {
        return this.post(`api/element/upload-done`, data);
    }

    // SUPPLIER ////////////::
    getSuppliers() {
        return this.get<{ suppliers: Supplier[] }>('api/supplier');
    }
    createSupplier({ file, ...supplier }: any): any {
        return this.sendFile(file, supplier, 'POST', 'api/supplier');
    }
    setSupplier(id: string, { file, ...supplier }: Element) {
        return this.sendFile(file, supplier, 'PUT', 'api/supplier/' + id);
    }
    deleteSupplier(id: string) {
        return this.delete(`api/supplier`, id);
    }

    ///// ORDERS ////////////
    createOrder(order: any, file?: File) {
        return file ? this.sendFile(file, order, 'POST', 'api/order') : this.post('api/order', order);
    }
    getOrders(supplierId: string) {
        return this.get('api/supplier/' + supplierId + '/order');
    }
    getOrder(orderId: string, page: number, search: string, searchProductId: string): Promise<{ order: Order }> {
        return this.get(
            `api/order/${orderId}?page=${page || 0}&search=${search || ''}&searchProductId=${searchProductId}`
        );
    }
    setOrder(orderId: string, order: any) {
        return this.put(`api/order`, orderId, order);
    }
    setOrders(orders: Order[], noFeedback: boolean) {
        return this.post('api/order/sort', orders, { noFeedback });
    }
    setOrderProduct(product: Partial<Product>, file?: File) {
        return this.sendFile(file, product, 'PUT', `api/order/product`);
    }
    setOrderImage(file: File, orderId: string) {
        return this.sendFile(file, {}, 'PUT', `api/order/${orderId}/image`);
    }
    deleteOrder(id: string) {
        return this.delete(`api/order`, id);
    }
    getUserOrders(): Promise<{ orders: UserOrder[] }> {
        return this.get(`api/user-order`);
    }
    getUserOrder(id: string): Promise<{ order: UserOrder }> {
        return this.get(`api/user-order/` + id);
    }
    createUserOrder(data: UserOrder) {
        return this.post('api/user-order', data);
    }
    addProductImage(file: any, body: any) {
        return this.sendFile(file, body, 'POST', `api/order/${body.orderId}/add-product-picture`);
    }

    sendContactEmail(value: any) {
        return this.post(`api/app/contact`, value);
    }

    // callback action

    activationLink(token: string) {
        return this.get('api/users/activate-account?token=' + token, { noSuccessFeedback: false });
    }
    orderAction(token: string) {
        return this.post('api/order/actions', { token });
    }
    search(value: string) {
        return this.get('api/search/' + value);
    }

    // usefull link

    getUsefullLinks() {
        return this.get<UsefullLink[]>('api/app/usefull-link');
    }
    addUsefullLink(file: File, usefullLink: UsefullLink) {
        return this.sendFile(file, usefullLink, 'POST', 'api/app/usefull-link');
    }
    setUsefullLink(usefullLinks: UsefullLink[]) {
        return this.put('api/app', 'usefull-link', usefullLinks);
    }

    // Collaborators

    addTeam(team: Partial<Team>) {
        return this.post('api/team', team);
    }
    deleteTeam(team: Team) {
        return this.delete('api/team', team._id);
    }
    addCollaborator({ imgUrl, ...collaborator }: Partial<Collaborator>) {
        return this.sendFile(imgUrl, collaborator, 'POST', 'api/collaborator');
    }
    editCollaborator({ imgUrl, ...collaborator }: Partial<Collaborator>) {
        return this.sendFile(imgUrl, collaborator, 'PUT', 'api/collaborator');
    }
    deleteCollaborator(collaborator: Collaborator) {
        return this.delete('api/collaborator', collaborator._id);
    }
    getCollaboratorsTeam() {
        return this.get<Team[]>('api/collaborator/team');
    }
}
export default getInstance();
