import * as Sentry from '@sentry/react';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
// import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://7de0b79dcd534b27bbb3554edf53ea5d@o516115.ingest.sentry.io/5622162',
        // integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // tracesSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
