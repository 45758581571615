import { Lang } from "../Types/lang";

type TranslateActions = {
  loadLiterals: (lang: Lang) => void;
  changeLang: (lang: Lang) => void;
};

export const translateActionsName = {
  loadLiterals: "LOAD_LITERALS",
  changeLang: "CHANGE_LANG",
};

let translateDispatch: any = null;

export const setTranslateDispatch = (dispatch: Function) => {
  translateDispatch = dispatch;
};

export const translateActions: TranslateActions = {} as TranslateActions;
for (const translateAction in translateActionsName) {
  (translateActions as any)[translateAction] = (payload: any) => {
    translateDispatch!({
      type: (translateActionsName as any)[translateAction],
      payload: payload,
    });
  };
}
