import React, { useContext, useState } from 'react';
import { TranslateContext } from '../Store/translate.reducer';
import './CookiesBanner.scss';

export default function CookiesBanner() {
    const t = useContext(TranslateContext);
    const [cookiesAnwser, setCookiesAnwser] = useState(localStorage.getItem('cookies'));

    const setCookies = (accept: any) => () => {
        localStorage.setItem('cookies', accept + '');
        setCookiesAnwser(accept);
    };

    return cookiesAnwser ? null : (
        <div className="cookies-banner">
            <div>
                <h4>{t.text('COOKIES_TITLE')}</h4>

                <div>{t.text('COOKIES_TEXT')}</div>
            </div>
            <div>
                <span onClick={setCookies('false')}>{t.text('DONT_ACCEPT')}</span>
                <button className="blue" onClick={setCookies('true')}>
                    {t.text('ACCEPT_COOKIES')}
                </button>
            </div>
        </div>
    );
}
