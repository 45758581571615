import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import { appActions } from '../../Store/app.actions';
import { AppContext } from '../../Store/app.reducer';
import { classN } from '../utils';
import FontIcon from './FontIcon';
import './Modal.scss';

export default function Modal() {
    const { modal } = useContext(AppContext);
    useEffect(() => {
        ReactModal.setAppElement('#root');
    }, []);

    return (
        <ReactModal
            isOpen={modal.active}
            className={`Modal ${modal.size}`}
            portalClassName={classN('ReactModalPortal', modal.className)}
            onRequestClose={appActions.closeModal}
        >
            {modal.title && (
                <div className="modal-title">
                    <div></div>
                    {modal.title}
                    <div className="close-button">
                        <FontIcon name="close" onClick={appActions.closeModal} />
                    </div>
                </div>
            )}
            <div className="modal-content">{modal.content}</div>
        </ReactModal>
    );
}
