import React, { useContext } from 'react';
import FontIcon from '../../Shared/Component/FontIcon';
import { appActions } from '../../Store/app.actions';
import { TranslateContext } from '../../Store/translate.reducer';
import './Footer.scss';
import ContactForm from './Footer/ContactForm';

export default function Footer() {
    const t = useContext(TranslateContext);
    const showContactForm = () => {
        appActions.openModal({
            size: 'medium',
            title: t.text('CONTACT_FORM'),
            content: <ContactForm />,
        });
    };
    return (
        <footer id="footer">
            <div className="footer-body">
                <div>
                    <h3>{t.text('INFORMATION')}</h3>
                    <div>
                        <div>{t.text('PSA_ADDRESS')}</div>
                        <div>{t.text('PSA_CITY')}</div>
                    </div>
                    <div>{t.text('CGU')}</div>
                </div>
                <div>
                    <h3>{t.text('CONTACT_US')}</h3>
                    <div onClick={showContactForm}>{t.text('CONTACT_FORM')}</div>
                    <div>
                        <a href="tel:078151011">078 15 10 11</a>
                    </div>
                </div>
                <div>
                    <h3>{t.text('FOLLOW_US')}</h3>
                    <a href="https://www.linkedin.com/company/psa-retail-distrigo-belgium" target="_blank">
                        <FontIcon name="linkedin" />
                    </a>
                    <a href="https://www.facebook.com/psa.retail.spareparts" target="_blank">
                        <FontIcon name="facebook" />
                    </a>
                </div>
            </div>

            <div className="copyright">
                <a href="https://stipo-consulting.be/" target="_blank">
                    Powered by Stipo Consulting
                </a>
            </div>
        </footer>
    );
}
