type ModalOptions = {
    title?: string;
    size?: 'small' | 'medium' | 'full';
    content: any;
    className?: string;
};

type AppActionsType = {
    openModal: (modal: ModalOptions) => void;
    closeModal: () => void;
    openSidemenu: (sidemenu: any) => void;
    closeSidemenu: () => void;
    loading: (active: boolean) => void;
    getDashboardInfo: (data: any) => void;
};

export const appActionsName = {
    openModal: 'OPEN_MODAL',
    closeModal: 'CLOSE_MODAL',
    openSidemenu: 'OPEN_SIDEMENU',
    closeSidemenu: 'CLOSE_SIDEMENU',
    loading: 'LOADING',
    getDashboardInfo: 'GET_DASHBOARD_INFO',
};

let dispatch: any = null;

export const setAppActionDispatch = (newDispatch: Function) => {
    dispatch = newDispatch;
};

export const appActions: AppActionsType = {} as AppActionsType;
for (const action in appActionsName) {
    (appActions as any)[action] = (payload: any) => {
        dispatch!({
            type: (appActionsName as any)[action],
            payload: payload,
        });
    };
}
