import React, { useContext, useState } from 'react';
// import magasinImg from '../../assets/images/magasin.jpg';
import clarckImg from '../../assets/images/clark.jpg';
import { TranslateContext } from '../../Store/translate.reducer';
import './SecondSection.scss';
import belgiumImg from '../../assets/images/belgium.png';
import FontIcon from '../../Shared/Component/FontIcon';

export default function SecondSection() {
    const t = useContext(TranslateContext);
    return (
        <section className="second-section" id="second-section">
            <div className="line-container blue">
                <div className="blue-line"></div>
            </div>
            <div className="left">
                <div className="img-container">
                    <div className="warehouse"></div>
                    <FontIcon className="arrow brabant-f" name="arrow" />
                    <FontIcon className="arrow hainaut" name="arrow" />
                    <FontIcon className="arrow liege" name="arrow" />
                    <FontIcon className="arrow flandre-occ" name="arrow" />
                    <FontIcon className="arrow anvers" name="arrow" />
                    <FontIcon className="arrow luxembourg" name="arrow" />
                    <img src={belgiumImg} alt="" />
                </div>
            </div>
            <div className="right">
                <h2>{t.text('DELIVERY_IN_BELGIUM')}</h2>
                <p> {t.text('DELIVERY_IN_BELGIUM_1')} </p>
            </div>
        </section>
    );
}
