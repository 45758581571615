import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import apiService from '../../Shared/Service/api.service';
import { getQueryParams } from '../../Shared/utils';
import { appActions } from '../../Store/app.actions';
import { TranslateContext } from '../../Store/translate.reducer';

type Props = {
    initialValues?: any;
};

export default function Callback({ initialValues }: Props) {
    const t = useContext(TranslateContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { action } = useParams();

    useEffect(() => {
        appActions.loading(true);
        switch (action!) {
            case 'activate-account':
                apiService
                    .activationLink(getQueryParams('token'))
                    .then((value) => {
                        apiService
                            .getCurrentUser(true)
                            .then(() => {
                                navigate('/app');
                                appActions.loading(false);
                            })
                            .catch(() => {
                                navigate('/app');
                                appActions.loading(false);
                            });
                    })
                    .catch(() => {
                        appActions.loading(false);
                    });
                break;
            case 'reset-password':
                navigate('/login' + location.search + '&reset-password=true');
                appActions.loading(false);
                break;
            case 'order-action':
                apiService
                    .orderAction(getQueryParams('token'))
                    .then((value) => {
                        appActions.openModal({
                            size: 'medium',
                            title: t.text('CHANGE_ORDER_STATUS'),
                            content: (
                                <div style={{ color: '#FFF', fontSize: '18px' }}>{t.text('EMAIL_ORDER_SENT')}</div>
                            ),
                        });
                        navigate('/app');
                        appActions.loading(false);
                    })
                    .catch(() => {
                        appActions.loading(false);
                    });
                appActions.loading(false);
                break;
            default:
                navigate('/');
                appActions.loading(false);
        }
    }, []);

    return null;
}
