import React from 'react';
import loadLang from '../assets/i18n/index';
import { ReducerAction } from '../Types/store';
import { translateActionsName } from './translate.actions';

let text = {};

type TranslateReducer = {
    availableLang: { description: string; value: string }[];
    currentLang: 'fr' | 'nl';
    text: (key: string) => string;
};

export const translateInitData: any = {
    availableLang: [
        { description: 'Français', value: 'fr' },
        { description: 'Nederlands', value: 'nl' },
    ],
    currentLang: '',
    text: (key: string) => searchText(key, text),
};

export const TranslateContext = React.createContext<TranslateReducer>(translateInitData);

export const translateReducer = (state: any, { type, payload }: ReducerAction) => {
    switch (type) {
        case translateActionsName.loadLiterals:
            text = payload;
            return { ...state };
        case translateActionsName.changeLang:
            if (state.currentLang !== payload) {
                text = loadLang(payload);
            }
            return { ...state, currentLang: payload };
        default:
            return state;
    }
};

const searchText = (key: string, text: any) =>
    key.split('.').reduce((prev, curr) => (prev && prev[curr]) || reportProblem(key), text);

const reportProblem = (key: string) => {
    //   if (__STAGING__) {
    //     init({ dsn: "sentry url" });
    //   }

    //   if (Object.keys(text).length !== 0) {
    //     function select(state: StoreState) {
    //       return state.translate.currentLang;
    //     }
    //     const currentLang = select(storeSetup.store.getState());
    //     if (__DEV__) {
    //       throw `Missing translation for ${currentLang} - ${key}`;
    //     }
    //     if (Object.keys(fallbackText).length === 0) {
    //       fallbackText = loadLang("fr");
    //     }
    //     return searchText(key, fallbackText);
    //   }

    return 'Missing translation';
};
