import React, { Suspense, useLayoutEffect, useReducer, useState } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Callback from './App/Callback/Callback';
import CookiesBanner from './App/CookiesBanner';
import LandingPage from './App/LandingPage';
import Loading from './App/Loading/Loading';
import Login from './App/Login';
import Modal from './Shared/Component/Modal';
import SideMenu from './Shared/Component/SideMenu';
import apiService from './Shared/Service/api.service';
import localStorageService from './Shared/Service/localstorage.service';
import { appActions, setAppActionDispatch } from './Store/app.actions';
import { AppContext, appDefaultState, appReducer } from './Store/app.reducer';
import { setTranslateDispatch, translateActions } from './Store/translate.actions';
import { TranslateContext, translateInitData, translateReducer } from './Store/translate.reducer';
import { setUserActionDispatch, userActions } from './Store/user.actions';
import { UserContext, userDefaultState, userReducer } from './Store/user.reducer';

// const NotLogged = React.lazy(() => import("./NotLogged"));
const MyDistrigo = React.lazy(() => import('./App/MyDistrigo'));

function App() {
    const [translateState, translateDispatch] = useReducer(translateReducer, translateInitData);
    const [userState, userDispatch] = useReducer(userReducer, userDefaultState);
    const [appState, appDispatch] = useReducer(appReducer, appDefaultState);
    const [initLoading, setInitLoading] = useState(true);
    setTranslateDispatch(translateDispatch);
    setUserActionDispatch(userDispatch);
    setAppActionDispatch(appDispatch);

    useLayoutEffect(() => {
        translateActions.changeLang('fr');
        const user = localStorageService.getUser();
        if (user) {
            apiService.setUserToken(user.token);
            userActions.login(user);
            apiService.getCurrentUser();
        }
        appActions.loading(false);
        setInitLoading(false);
    }, []);

    return (
        <BrowserRouter>
            <UserContext.Provider value={userState}>
                <TranslateContext.Provider value={translateState}>
                    <AppContext.Provider value={appState}>
                        <div className="App">
                            <ReactNotifications />
                            <SideMenu />
                            <Modal />
                            <Loading />
                            {!initLoading && (
                                <Suspense fallback={<div>{translateState.text('LOADING')}...</div>}>
                                    <Routes>
                                        <Route
                                            path="/login"
                                            element={userState.user ? <Navigate replace to="/app" /> : <Login />}
                                        />
                                        <Route
                                            path="/app/*"
                                            element={userState.user ? <MyDistrigo /> : <Navigate replace to="/login" />}
                                        />
                                        <Route path="/callback/:action" element={<Callback />} />
                                        <Route path="*" element={<LandingPage />} />
                                    </Routes>
                                </Suspense>
                            )}
                            <CookiesBanner />
                        </div>
                    </AppContext.Provider>
                </TranslateContext.Provider>
            </UserContext.Provider>
        </BrowserRouter>
    );
}

export default App;
