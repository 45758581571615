import React from 'react';
import apiService from '../Shared/Service/api.service';
import localstorageService from '../Shared/Service/localstorage.service';
import { ReducerAction } from '../Types/store';
import { User } from '../Types/user';
import { translateActions } from './translate.actions';

export const userDefaultState = {
    user: undefined,
};
type UserContextType = {
    user?: User;
};

export const UserContext = React.createContext<UserContextType>(userDefaultState);

export const userReducer = (state: any, { type, payload }: ReducerAction) => {
    switch (type) {
        case 'GET_USER':
            const user = { ...payload, token: state.user.token };
            localstorageService.setUser(user);
            return {
                ...state,
                user,
            };
        case 'LOGIN':
            apiService.setUserToken(payload.token);
            localstorageService.setUser(payload);
            translateActions.changeLang(payload.lang);

            return {
                ...state,
                user: payload,
            };

        case 'LOGOUT':
            apiService.setUserToken('');
            localstorageService.setUser('');
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
};
