import React, { useContext, useState } from 'react';

import { TranslateContext } from '../../Store/translate.reducer';
import { UserContext } from '../../Store/user.reducer';
import { Collaborator } from '../../Types/collaborator';
import { OnClickEvent } from '../../Types/html';
import { UserRole } from '../../Types/UserRole';

import './CollaboratorCard.scss';
import FontIcon from './FontIcon';

type Props = Collaborator & { edit?: boolean; optionItems?: any; big?: boolean };

export default function CollaboratorCard({ edit, big, optionItems, imgUrl, name, email, role_fr, role_nl }: Props) {
    const t = useContext(TranslateContext);
    const { user } = useContext(UserContext);

    const [open, setOpen] = useState(false);

    const toggle = (e: OnClickEvent) => {
        e.stopPropagation();
        setOpen(!open);
    };

    return (
        <div className={`collaborator-card card ${big ? 'big' : ''}`}>
            <div className="img">
                <div
                    className="image"
                    style={{
                        backgroundImage: `url(${imgUrl})`,
                    }}
                ></div>
                {edit && user?.role === UserRole.SUPER_ADMIN && (
                    <div className="option-menu" onClick={toggle}>
                        <FontIcon name="three-dots" />
                    </div>
                )}
                <div className={`arrow ${open && 'open'}`}></div>
                <div className={`menu ${open && 'open'}`}>{optionItems}</div>
            </div>
            <div className="card-body">
                <div className="name">{name}</div>
                <div className="title">{t.currentLang === 'fr' ? role_fr : role_nl}</div>
                <div className="email">
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            </div>
        </div>
    );
}
