import AnimateScroll from 'animated-scroll-to';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactForm from '../../App/LandingPage/Footer/ContactForm';
import citroenImg from '../../assets/images/distrigo-psa-img-citroen.png';
import dsImg from '../../assets/images/distrigo-psa-img-ds.png';
import euroreparImg from '../../assets/images/distrigo-psa-img-eurorepar.png';
import distrigoLogo from '../../assets/images/Distrigo.png';
import peugeotImg from '../../assets/images/logo-peugeot.png';
import opelImg from '../../assets/images/opel-Logo.png';
import psaLogo from '../../assets/images/psa.png';
import { appActions } from '../../Store/app.actions';
import { TranslateContext } from '../../Store/translate.reducer';
import { userActions } from '../../Store/user.actions';
import { UserContext } from '../../Store/user.reducer';
import FontIcon from './FontIcon';
import './Header.scss';
import LangSelector from './LangSelector';

export default function Header() {
    const t = useContext(TranslateContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const showContactForm = () => {
        appActions.openModal({
            title: t.text('CONTACT_FORM'),
            size: 'medium',
            content: <ContactForm />,
        });
        appActions.closeSidemenu();
    };

    const scrollTo = (id: string) => () => {
        appActions.closeSidemenu();
        navigate('/');

        setTimeout(() => {
            const elem = document.getElementById(id)!;

            AnimateScroll(elem, {
                elementToScroll: document.getElementById('wrapper')!,
                // verticalOffset: -50,
            }).then((value) => {});
        }, 100);
    };

    const openNavMenu = () => {
        appActions.openSidemenu({
            title: t.text('NAVIGATION'),
            content: (
                <div className="header-sidemenu">
                    <div></div>
                    <nav className="right">
                        <div className="link" onClick={showContactForm}>
                            {t.text('CONTACT_US')}
                        </div>
                        <Link className="link" onClick={appActions.closeSidemenu} to="/app">
                            My distrigo
                        </Link>
                        {user ? (
                            <button onClick={userActions.logout}>{t.text('LOGOUT')}</button>
                        ) : (
                            <Link to="/login">
                                <button onClick={appActions.closeSidemenu}>{t.text('LOGIN')}</button>
                            </Link>
                        )}

                        <LangSelector />
                    </nav>
                    <div className="img-wrapper">
                        <div>
                            <img src={peugeotImg} alt="peugeot" />
                        </div>
                        <div>
                            <img src={citroenImg} alt="citroen" />
                        </div>
                        <div>
                            <img src={dsImg} alt="ds" />
                        </div>
                        <div>
                            <img src={opelImg} alt="opel" />
                        </div>
                        <div>
                            <img src={euroreparImg} alt="eurorepar" />
                        </div>
                    </div>
                </div>
            ),
        });
    };

    return (
        <header id="header">
            <nav className="left">
                <Link to="/">
                    <img src={psaLogo} alt="psa-logo" />
                    <img src={distrigoLogo} alt="distrigoLdistrigo-logo" />
                </Link>
            </nav>

            <div className="right">
                <FontIcon onClick={openNavMenu} className="menu-icon" name="menu" />
            </div>
            <nav className="right">
                <div className="link menu">
                    {t.text('MENU')}
                    <div className="items-container">
                        <div className="item" onClick={scrollTo('banner')}>
                            {t.text('ACCEUIL')}
                        </div>
                        <div className="item" onClick={scrollTo('psa-is')}>
                            {t.text('WHO_WE_ARE')}
                        </div>
                        <div className="item" onClick={scrollTo('who-we-are')}>
                            {t.text('OUR_MISSION')}
                        </div>
                        <div className="item" onClick={scrollTo('second-section')}>
                            {t.text('DELIVERY')}
                        </div>
                        <div className="item" onClick={scrollTo('sponsor')}>
                            {t.text('OUR_PARTS')}
                        </div>
                        <div className="item" onClick={scrollTo('team-section')}>
                            {t.text('THE_TEAM')}
                        </div>
                        <div className="item" onClick={scrollTo('map')}>
                            {t.text('FIND_US')}
                        </div>
                        <div className="item" onClick={scrollTo('parternship')}>
                            {t.text('OUR_SPONSOR')}
                        </div>
                    </div>
                </div>
                <div className="link" onClick={showContactForm}>
                    {t.text('CONTACT_US')}
                </div>
                <Link className="link" to="/app">
                    My distrigo
                </Link>
                {user ? (
                    <button onClick={userActions.logout}>{t.text('LOGOUT')}</button>
                ) : (
                    <Link to="/login">
                        <button>{t.text('LOGIN')}</button>
                    </Link>
                )}

                <LangSelector />
            </nav>
        </header>
    );
}
